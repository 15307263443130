import React from 'react';
import Send from '../assets/images/send.png';
import Discord from '../assets/images/discord.png';
import Twitter from '../assets/images/twitter.png';
import LinkedIn from '../assets/images/linkedin.png';
import Facebook from '../assets/images/facebook.png';
import { Link } from 'react-router-dom';

const ConnectWithUs = () => {
    return (
        <div className="bg-white py-12 px-4">
            <div className="text-center font-extrabold md:text-4xl text-2xl text-black mb-12">Connect with us now.</div>
            <div className='flex flex-wrap justify-center items-center gap-8 md:gap-16'>
            {/* <img alt='Facebook' src={Facebook} className='w-16 h-16 md:w-24 md:h-24' /> */}
                <img alt='Send' src={Send} className='w-16 h-16 md:w-24 md:h-24' />
                <img alt='Discord' src={Discord} className='w-16 h-16 md:w-24 md:h-24' />
                <a
      href="https://x.com/fadaqa_?s=21&t=NmpH23kFcZ3fDv_GcGvqxQ"
      target="_blank"
      rel="noopener noreferrer"
    ><img alt='Twitter' src={Twitter} className='w-16 h-16 md:w-24 md:h-24' /></a>
                  <a
      href="https://www.linkedin.com/company/fadaqa/"
      target="_blank"
      rel="noopener noreferrer"
    ><img alt='LinkedIn' src={LinkedIn} className='w-16 h-16 md:w-24 md:h-24' /></a>
            </div>
        </div>
    );
}

export default ConnectWithUs;