import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink,   } from 'react-router-dom';

import Footer from "../Components/Footer";
import Nav from "../Components/Nav";

const GetInvolved = () => {
    return (
        <div>
            
            <div className='flex flex-col items-center justify-center text-center pt-14'>
       <div className="flex justify-center">let's make a <div className="pl-2 bg-[#F5EC16]"> difference</div></div>
       <div className="text-[40px] font-extrabold">A world where no one is left behind.</div>
       <div className="text-[20px] font-medium pt-9 pb-12">We have curated the best mentors to take you on this journey towards self-development and we can’t 
wait to see yo smiling at the top . We decided to give you best by partnering with the best to make sure
you get the best out of your learning experience and hoping you will excel at the end of your cohort. </div>
<RouterLink spy={true} smooth={true} to="/getinvolved/donatePaymentOption" >
<div className=" px-6 py-5  justify-center bg-[#73AFC7] text-white text-center text-[20px] font-[500] rounded-md shadow-md cursor-pointer hover:bg-blue-600 h-[69px] w-[269px]  mt-9 mb-60" >
      Apply Now
    </div>
    </RouterLink>
      </div>
            
        </div>
    )
}

export default GetInvolved;