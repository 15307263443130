import React from 'react';
import Fadaqa from '../assets/images/fadaqa_pix.png';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';

const OurStory = () => {
  return (
    <div>
        
    <div className="container mx-auto py-12">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 ">
        <div className="pl-8 pt-6">
        <div className="text-[44px] font-bold text-center mb-8">VISION</div>
          <div className="text-[20px] ">
          Our founder’s vision is to empower, educate and inspire 2,000,000  individuals from low socioeconomic backgrounds, acquire tech skills, access opportunities and build solutions.
          </div>
          <div className="text-[44px] font-bold text-center mt-14 mb-8">Mission</div>
          <div className="text-lg">
          FadaQa mission is to empower, educate, inspire and foster a borderless TECH community where people from low socioeconomic backgrounds can access the skills, opportunities, network and support they need to improve their standard of living, gain financial independence and build innovative solutions, starting with Africa.
          </div>
        </div>
        <div className="p-4">
          <img src={Fadaqa} alt="Fadaqa" className="w-full h-auto object-cover object-center" />
        </div>
      </div>
    </div>
    
    </div>
  );
}

export default OurStory;