import React from 'react';
import Fadaqa from '../assets/images/fadaqa_pix.png';
import PartnerWithUsImage from '../assets/images/partner_with_us.png';


const PartnerWithUs = () => {
  return (
    <div>
        
    <div className="container mx-auto py-12">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 ">
        <div className="pl-8 pt-6">
        <div className="text-[44px] font-bold text-center mb-8">Partner with us</div>
          <div className="text-[20px] font-medium">
          Through sponsorships, donations, and partnerships, we can amplify our efforts and increase our impact rate. Your support will directly contribute to our initiatives, allowing us to empower people from low socioeconomic background with needed tech skills, opportunities and network
          </div>
          <div className=" px-6 py-5  justify-center bg-[#73AFC7] text-white text-center text-[20px] font-[500] rounded-md shadow-md cursor-pointer hover:bg-blue-600 h-[69px] w-[269px]  mt-9 mb-60" >
      Partner with us
    </div>
         
        </div>
        <div className="p-4">
          <img src={PartnerWithUsImage} alt="Fadaqa" className="w-full h-auto object-cover object-center" />
        </div>
      </div>
    </div>
    
    </div>
  );
}

export default PartnerWithUs;