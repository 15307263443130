const Donate = () => {

    return (
        <div className="bg-[#000000] h-[482px] w-full pt-16">
            <div className="font-[800] text-[40px] text-white text-center mb-6">Make a difference and stay in the loop.</div>
            <p className="text-center text-white">Help sponsor our events and programs by donating to our course , you <br/>
would be making a huge impact in the lives of individuals around you and your community.</p>

<div className="flex justify-center items-center mt-9">
  <div className="px-9 py-5 bg-[#73AFC7] text-white text-center text-[20px] font-[500] rounded-md shadow-md cursor-pointer hover:bg-blue-600 h-[69px] w-[269px]">
    Donate Now
  </div>
</div>
        </div>
    )
}


export default Donate;