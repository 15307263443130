import React from 'react';
import Image1 from '../assets/images/fadaqa_pix.jpg';
import Image2 from '../assets/images/fadaqa_pix2.jpg';
import Image3 from '../assets/images/fadaqa_pix3.jpg';
import Image4 from '../assets/images/fadaqa_pix.png';
import image5 from '../assets/images/fadaqa_pix5.jpg';
import image6 from '../assets/images/fadaqa_pix1.jpg'



const Introduction = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="container mx-auto">
        <div className="text-center mb-8">
        <h1 class="text-2xl md:text-4xl font-extrabold mt-24">Fostering a Borderless Tech Community</h1>
          <p className="text-lg mt-4">A Non-profit organisation focused on skill empowerment...</p>
          <div className="flex justify-center mt-9 mb-20">
            <div className="px-6 py-5 bg-[#73AFC7] text-white text-center text-lg font-medium rounded-full shadow-md cursor-pointer hover:bg-black h-[69px] w-[269px]">
              Apply Now
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="p-4">
            <img src={Image4} alt="Img 1" className="w-full h-[420px] object-cover object-center rounded-[20px] shadow-md" />
          </div>
          {/* <div className="p-4 flex flex-col space-y-8">
            <img src={Image1} alt="Img 2" className="w-full h-auto object-cover object-center rounded-md  shadow-md" />
            <img src={image5} alt="Img 3" className="w-full h-auto object-cover object-center rounded-md  shadow-md" />
          </div> */}
          <div className="p-4 flex flex-col space-y-8">
            <img src={Image2} alt="Img 2" className="w-full h-auto object-cover object-center rounded-full shadow-md" />
            <img src={Image3} alt="Img 3" className="w-full h-auto object-cover object-center rounded-full shadow-md" />
          </div>
          <div className="p-4 flex flex-col space-y-8">
            <img src={image6} alt="Img 2" className="w-full h-auto object-cover object-center rounded-[20px] shadow-md" />
            <img src={Image1} alt="Img 3" className="w-full h-auto object-cover object-center rounded-[20px] shadow-md" />
          </div>
          {/* <div className="p-4">
            <img src={Image1} alt="Img 4" className="w-full h-auto object-cover object-center rounded-md shadow-md" />
          </div> */}
          {/* image5 */}
        </div>
       
      </div>
    </div>
  );
}

export default Introduction;