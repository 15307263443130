import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import { CiMenuFries } from 'react-icons/ci';

const Nav = () => {
  const [click, setClick] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  useEffect(() => {
    setActiveLink(window.location.pathname);
  }, []);

  const content = (
    <div className={`lg:hidden fixed inset-y-0 left-0 w-64 bg-slate-900 transition-opacity z-50 ${click ? 'opacity-95' : 'opacity-0'}`}>
      <ul className='text-center text-xl p-4'>
        <NavLink
          to='/'
          className={({ isActive }) =>
            `block my-4 py-4 border-b border-slate-800 hover:bg-slate-800 ${
              isActive ? 'text-fuchsia-600 font-bold border-b-2 border-fuchsia-600' : 'text-white'
            }`
          }
          onClick={closeMobileMenu}
        >
          Home
        </NavLink>
        <NavLink
          to='/ourstory'
          className={({ isActive }) =>
            `block my-4 py-4 border-b border-slate-800 hover:bg-slate-800 ${
              isActive ? 'text-fuchsia-600 font-bold border-b-2 border-fuchsia-600' : 'text-white'
            }`
          }
          onClick={closeMobileMenu}
        >
          Our Story
        </NavLink>
        <NavLink
          to='/getinvolved'
          className={({ isActive }) =>
            `block my-4 py-4 border-b border-slate-800 hover:bg-slate-800 ${
              isActive ? 'text-fuchsia-600 font-bold border-b-2 border-fuchsia-600' : 'text-white'
            }`
          }
          onClick={closeMobileMenu}
        >
          Get Involved
        </NavLink>
        <NavLink
          to='/faqs'
          className={({ isActive }) =>
            `block my-4 py-4 border-b border-slate-800 hover:bg-slate-800 ${
              isActive ? 'text-fuchsia-600 font-bold border-b-2 border-fuchsia-600' : 'text-white'
            }`
          }
          onClick={closeMobileMenu}
        >
          FAQs
        </NavLink>
        <NavLink
          to='/blog'
          className={({ isActive }) =>
            `block my-4 py-4 border-b border-slate-800 hover:bg-slate-800 ${
              isActive ? 'text-fuchsia-600 font-bold border-b-2 border-fuchsia-600' : 'text-white'
            }`
          }
          onClick={closeMobileMenu}
        >
          Blog
        </NavLink>
      </ul>
    </div>
  );

  return (
    <nav>
      <div className='h-16 lg:h-20 flex justify-between z-50 bg-[#1a202c] text-white lg:py-5 px-6 md:px-10 lg:px-20 py-4 flex-1'>
        <div className='flex items-center flex-1'>
          <div className='flex text-3xl font-bold'>
            Fada<div className='text-[#73AFC7]'>Q</div>a
          </div>
        </div>

        {/* Mobile Menu */}
        <div className='lg:hidden block'>
          <button
            className='block transition text-2xl'
            onClick={handleClick}
          >
            {click ? <FaTimes /> : <CiMenuFries />}
          </button>
        </div>

        {/* Desktop Menu */}
        <div className='hidden lg:flex lg:flex-1 items-center justify-end font-normal'>
          <ul className='flex gap-8 text-[18px]'>
            <NavLink
              to='/'
              className={({ isActive }) =>
                `hover:text-fuchsia-600 transition cursor-pointer ${
                  isActive ? 'text-fuchsia-600 font-bold border-b-2 border-fuchsia-600' : ''
                }`
              }
            >
              Home
            </NavLink>
            <NavLink
              to='/ourstory'
              className={({ isActive }) =>
                `hover:text-fuchsia-600 transition cursor-pointer ${
                  isActive ? 'text-fuchsia-600 font-bold border-b-2 border-fuchsia-600' : ''
                }`
              }
            >
              Our Story
            </NavLink>
            <NavLink
              to='/getinvolved'
              className={({ isActive }) =>
                `hover:text-fuchsia-600 transition cursor-pointer ${
                  isActive ? 'text-fuchsia-600 font-bold border-b-2 border-fuchsia-600' : ''
                }`
              }
            >
              Get Involved
            </NavLink>
            <NavLink
              to='/faqs'
              className={({ isActive }) =>
                `hover:text-fuchsia-600 transition cursor-pointer ${
                  isActive ? 'text-fuchsia-600 font-bold border-b-2 border-fuchsia-600' : ''
                }`
              }
            >
              FAQs
            </NavLink>
            <NavLink
              to='/blog'
              className={({ isActive }) =>
                `hover:text-fuchsia-600 transition cursor-pointer ${
                  isActive ? 'text-fuchsia-600 font-bold border-b-2 border-fuchsia-600' : ''
                }`
              }
            >
              Blog
            </NavLink>
          </ul>
        </div>
      </div>

      {/* Mobile Menu Content */}
      {content}
    </nav>
  );
};

export default Nav;