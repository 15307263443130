import React from 'react';
import MeetTheTeamPix from '../assets/images/meet_the_team.jpg';
import PartnerWithUsImage from '../assets/images/partner_with_us.png';


const MeetTheTeam = () => {
  return (
    <div>
        
    <div className="container mx-auto py-12">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 ">
        <div className="pl-8 pt-6">
        {/* <div className=" px-6 py-5  justify-center bg-transparent border-gray-100 text-black text-center text-[20px] font-[500] rounded-md shadow-md cursor-pointer hover:bg-blue-600 h-[69px] w-[269px]  mt-9 " >
      Partner with us
    </div> */}
        <div className="text-[44px] font-bold text-center mb-8 mt-24">Meet The Team</div>
          <div className="text-[20px] font-medium">
          Peep the faces behind the initiative and impacts here at Fadaqa.These wonderful people work behind the scene, everyday to keep our vision working.
          </div>
        
         
        </div>
        <div className="p-4">
          <img src={MeetTheTeamPix} alt="Meet the team" className="w-full h-auto object-cover rounded-[20px]  object-center" />
        </div>
      </div>
    </div>
    
    </div>
  );
}

export default MeetTheTeam;