import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import Nav from './Components/Nav';
import Footer from './Components/Footer';

import Home from './features/Home';
import GetInvolved from './pages/GetInvolved';
import NotFoundPage from './pages/NotFoundPage';

import DonatePaymentOptions from './pages/DonatePaymentOption';
import BitcoinPayment from './pages/BitcoinPayment';
import FAQs from './pages/FAQs';
import OurStory from './pages/OurStory';
import PartnerWithUs from './pages/PartnerWithUs';
import MeetTheTeam from './pages/MeetTheTeam';
import ContactUs from './pages/ContactUs';
import JoinUs from './pages/JoinUs';
import { Helmet } from 'react-helmet';

const router = createBrowserRouter([
{  
  path: '/',
  element: <Home />,
  errorElement: <NotFoundPage />
}, 
{
  path: '/bitcoin',
  element: <BitcoinPayment/>,
  // children: []
},

{
  path: '/faqs',
  element: <FAQs/>
},
{
  path: '/getinvolved',
  element: <GetInvolved/>,
  // children: []
},
{
  // /getinvolved/donatePaymentOption
  path: '/donatePaymentOption',
  element: <DonatePaymentOptions/>
},

{
  path: '/ourstory',
  element: <OurStory/>
},


]);

const App = () => {
  return (
  
   <React.StrictMode>
     <Helmet>
                <meta charSet="utf-8" />
                <title>Fadaqa</title>
                <link rel="canonical" href="http://mysite.com/example" />
                <meta name="description" content="Fadaqa application tech coding expert learning software frontend backend data anlysis project management product design community support " />
            </Helmet>
    <Nav/>
    {/* <RouterProvider router={router} />
     */}
      <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='bitcoin' element={<BitcoinPayment />}/>
      <Route path='contactus' element={<ContactUs />} />
      <Route path='donatePaymentOption' element={<DonatePaymentOptions />}/>
      <Route path='faqs' element={<FAQs />}/>
     <Route path='getinvolved' element={<GetInvolved />}/>
     <Route path='joinus' element={<JoinUs />} />
     <Route path='meetteam' element={<MeetTheTeam />} />
     <Route path='ourstory' element={<OurStory />}/>
     <Route path='/partnerWithUs' element={<PartnerWithUs />} />
     </Routes>
    <Footer />
   </React.StrictMode>
  // <div>
  //   <Routes>
  //     <Route path='/' element={<Home/>}/>
  //     <Route path='getinvolved' element={<GetInvolved />}/>
  //   </Routes>
  // </div>
  );
}

export default App;