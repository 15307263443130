import React from 'react';
import Slider from 'react-slick';
import SliderImg1 from '../assets/images/slider.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NoBarrierTechSecond = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div className="relative w-full h-[462px] md:h-[462px] h-auto">
      <Slider {...settings}>
        <div>
          <img
            src={SliderImg1}
            alt="Background 1"
            className="w-full h-[462px] md:h-[462px] h-auto object-cover"
          />
        </div>
        <div>
          <img
            src={SliderImg1}
            alt="Background 2"
            className="w-full h-[462px] md:h-[462px] h-auto object-cover"
          />
        </div>
        <div>
          <img
            src={SliderImg1}
            alt="Background 3"
            className="w-full h-[462px] md:h-[462px] h-auto object-cover"
          />
        </div>
      </Slider>
      {/* <div className="absolute inset-0 flex items-center justify-center p-8 bg-black bg-opacity-50">
        <div className="text-white text-center">
          <div className="text-2xl font-extrabold md:mb-8 mb-4 md:text-4xl text-3xl mt-7">
            Get a second chance at your first Tech skill.
          </div>
          <div className="text-lg font-extrabold mb-8 md:text-4xl text-3xl"></div>
          <div className="text-xl font-medium">
            We are giving individuals a chance to gain exceptional tech skills and thrive in the
            <br />
            tech industry and make a living out of it.
          </div>
          <div className="flex justify-center mt-9">
            <div className="px-6 py-5 bg-[#73AFC7] text-white text-center text-lg font-medium rounded-md shadow-md cursor-pointer hover:bg-blue-600 h-[69px] w-[269px]">
              Apply Now
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default NoBarrierTechSecond;