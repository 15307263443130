const WaitingForYou = () => {
    return (
        <div className=" justify-center items-center p-8 pb-9 bg-black text-white">
            <div className="text-[#73AFC7] md:pl-96 pr-56">Who we are</div>
            <div className="flex justify-center md:text-3xl text-[25px] font-bold text-center pb-4" > <div> We've been waiting for </div><div className="bg-[#F5EC16]">  you.</div>  </div>
            <div className="text-[20px] font">We have curated the best mentors to take you on this journey towards self-development and we can’t 
wait to see yo smiling at the top . We decided to give you best by partnering with the best to make sure
you get the best out of your learning experience and hoping you will excel at the end of your cohort. </div>
        </div>
    )
}


export default WaitingForYou