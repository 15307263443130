import React from 'react';
import QuestionAnswer from '../New_components.jsx/QuestionAnswer';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';


const FAQs = () => {
  const questionsAnswers = [
    { question: 'What is FadaQa about?', answer: 'FadaQa is a non-for-profit organization.' },
    { question: 'Is FadaQa a private or Non-for-profit organization?', answer: 'FadaQa is a non-for-profit organization.' },
    { question: 'What is FadaQa’s Mission?', answer: 'FadaQa is a non-profit with the mission to empower, educate, inspire and foster a borderless TECH community where people from low socioeconomic backgrounds can access the skills, opportunities, network and support they need to improve their standard of living, gain financial independence and build innovative solutions, starting with Africa.'},
    { question: 'What are the courses offered by FadaQa?', answer: 'FadaQa offers a wide range of tech courses Including but not limited to: Product Design, Project Management, Data Analytics, Frontend Development, Backend Development, Blockchain Technology, AI & Robotics, Cybersecurity and Digital Marketing. However, we are starting with the first five.' },
    { question: 'Do I need to pay to acquire any courses?', answer: 'No, all our courses are provided completely free of charge. ' },
    { question: 'How many courses can I enroll for per time?', answer: 'FadaQa does not limit the number of courses a student can enroll in at a time. Students are free to take as many courses as they can manage, depending on their individual learning goals and schedules.' },
    { question: 'Which social media platforms is FadaQa on?', answer: 'FadaQa has an active presence on several social media platforms, including Facebook, Twitter, Instagram, and LinkedIn' },
    { question: 'How can I partner with FadaQa?', answer: 'We welcome partnerships with organizations, educational institutions, and individuals who share our mission. You can reach out to our partnership team via the contact us button on our page, to explore opportunities for collaboration, or joint initiatives.' },
    { question: 'How can I volunteer as a tutor for FadaQa?', answer: "FadaQa has a volunteer tutor program where individuals with expertise in various subjects can contribute their time and knowledge to support student learning. If you're interested in becoming a tutor, Kindly reach out to us on any of our social media platforms." },
    { question: 'How can I apply to courses offered by FadaQa?', answer: "Visit our website, browse the course catalog, and apply for the course you're interested in." },
    { question: 'What are your learning methods at FadaQa?', answer: 'FadaQa utilizes a variety of engaging learning methods to cater to different learning styles. Our classes include a combination of live sessions, interactive classes, hands-on projects, practical assignment, discussion/peer interactions group and feedbacks from instructors.' },
   
    { question: 'What is FadaQa’s Mission?', answer: 'The useState hook allows you to add state to functional components in React.' },
    // Add more questions and answers here
  ];

  return (
    <div className=''>
        
        <div className="flex justify-center mt-14">We've got you<div className="pl-2 bg-[#F5EC16]"> covered</div></div>
    <div className="max-w-2xl mx-auto p-4 mb-36">
      <h2 className="text-3xl justify-center text-center font-bold mb-6">Frequently Asked Questions.</h2>
      {questionsAnswers.map((qa, index) => (
        <QuestionAnswer key={index} question={qa.question} answer={qa.answer} />
      ))}
    </div>
    
    </div>
  );
};

export default FAQs;