import React from 'react';

const ContactUs = () => {
  return (
    <div className="flex flex-wrap gap-6 p-6 justify-center">
      <div className="w-full md:flex-1 md:min-w-[45%] p-6 justify-center items-center mt-8 md:mt-28">
        <div>
          <h2 className="text-2xl font-bold mb-4">We would love to hear from you</h2>
          <p>
            Kindly reach out to us using any of the medium and we will be sure to get back to you.
          </p>
        </div>
      </div>
      <div className="w-full md:flex-1 md:min-w-[45%] bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-bold mb-4">Get In Touch</h2>
        <div className='flex flex-col md:flex-row justify-between'>
          <div className='mb-4 md:mb-0'>
            <h5 className='font-bold'>Telephone:</h5>
            <div>+234 810 088 4774</div>
          </div>
          <div>
            <h5 className='font-bold'>Whatsapp:</h5>
            <div>+234 810 088 4774</div>
          </div>
        </div>
        <h2 className='mt-9 font-bold'>Email</h2>
        <div>Batly@fadaqa.org</div>
        <div>Support@fadaqa.org</div>
        <div className='mb-9'>Partnership@fadaqa.org</div>
        <div className='font-bold'>Address</div>
        <p>
          Our office is located at 1234 Example St, City, Country. We're open from 9 AM to 5 PM, Monday to Friday.
        </p>
      </div>
    </div>
  );
};

export default ContactUs;