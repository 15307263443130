import React from 'react';

// Sample local images
import Image1 from '../assets/images/image1.png';
import Frontend from '../assets/images/frontend.png';
import Backend from '../assets/images/backend.png';
import ProductDesign from '../assets/images/product_design.png';
import ProductManagement from '../assets/images/product_management.png';
import CommunitySupport from '../assets/images/community_support.png';
import DataAnalysis from '../assets/images/data_analysis.jpg';
import Arrow from '../assets/images/arrow.png';
// import Image2 from '../assets/images/image2.jpg';
// import Image3 from '../assets/images/image3.jpg';
// import Image4 from '../assets/images/image4.jpg';
// import Image5 from '../assets/images/image5.jpg';
// import Image6 from '../assets/images/image6.jpg';

const Card = ({ image, title, subtitle }) => (
  <div className="w-full md:w-1/2 lg:w-1/3 p-4">
     {/* shadow-lg */}
    <div className="bg-white rounded-lg overflow-hidden">
      <img src={image} alt={title} className="w-full h-64  object-cover object-center ml-6" />
      <div className="p-4">
        <h2 className="text-[20px] font-extrabold mb-2">{title}</h2>
        <p className=" text-[16px] font-normal text-[#292727]">{subtitle}</p>
        <div className='flex'>Apply <img src={Arrow} alt='arrow' className=" object-cover object-center ml-6" /> </div>
      </div>
    </div>
  </div>
);

const TechStack = () => {
  const cards = [
    { id: 1, image: Frontend, title: 'Front–end Development', subtitle: 'Learn in–demand Web design skills using CSS, HTML etc. Front–end frameworks can be used anywhere' },
    { id: 2, image: Backend, title: 'Back–end Development', subtitle: 'Learn in–demand Web design skills using CSS, HTML etc. Front–end frameworks can be used anywhere.' },
    { id: 3, image: ProductDesign, title: 'Product Design', subtitle: 'Learn in–demand Web design skills using CSS, HTML etc. Front–end frameworks can be used anywhere.' },
    { id: 4, image: ProductManagement, title: 'Project management', subtitle: 'Learn in–demand Web design skills using CSS, HTML etc. Front–end frameworks can be used anywhere.' },
    { id: 5, image: DataAnalysis, title: 'Data–Analysis', subtitle: 'Learn in–demand Web design skills using CSS, HTML etc. Front–end frameworks can be used anywhere.' },
    { id: 6, image: CommunitySupport, title: 'Community Support', subtitle: 'Interact with a supportive community of individuals who understand and empathize with your mental health journey.' },
  ];

  return (
    <div className=" mx-auto px-4 md:px-8 lg:px-12 py-8 bg-[#FEFEFE]">
      <div className='mt-8 font-bold'>Unlock your potential and embark on a journey of career growth and skill development with our programs, designed to equip you with in-demand tech skills at no cost.</div>
      {/* <h1 className="text-2xl font-bold mb-4">Six Cards</h1> */}
      <div className="flex flex-wrap -mx-4">
        {cards.map((card) => (
          <Card key={card.id} image={card.image} title={card.title} subtitle={card.subtitle} />
        ))}
      </div>
    </div>
  );
};

export default TechStack;