import { Link as RouterLink,   } from 'react-router-dom';
import Facebook from '../assets/images/facebook.png';
import Instagram from '../assets/images/instagram.jpg';


const Footer = () => {

    // /donatePaymentOption
    return (
        <div className="bg-[#000000] w-full h-[462] pt-20 pb-14" >
            <div className="md:flex text-white justify-around text-center">
                <div className="pt-9">
                    <h3 className="font-[800] text-[24px]">Get involved</h3>
                    <RouterLink   to='/joinus'> <div className="font-[500] text-[14px] pt-4 pb-4">Join us</div></RouterLink>
                    <RouterLink  to='/donatePaymentOption'><div className="font-[500] text-[14px]  pb-4">Make a donation</div></RouterLink>
                    <RouterLink to='/partnerWithUs'> <div>Partner with us</div></RouterLink>
                </div>
                <div className="pt-9">
                    <h3  className="font-[800] text-[24px]">About us</h3>
                    
                    <RouterLink   to='meetteam'><div className="font-[500] text-[14px] pt-4 pb-4">Meet the team</div></RouterLink>
                    <RouterLink   to='/faqs'><div className="font-[500] text-[14px]  pb-4">FAQs</div></RouterLink>
                    <RouterLink  to='/contactus'> <div>Contact Us</div></RouterLink>
                </div>
                <div className="pt-9">
                    <h3  className="font-[800] text-[24px]">Community</h3>
                    <div className="font-[500] text-[14px] pt-4 pb-4">Follow us on social media</div>
                    <div className='flex gap-8 items-center justify-center'>
                    <a
      href="https://www.facebook.com/FadaQaHub?mibextid=LQQJ4d"
      target="_blank"
      rel="noopener noreferrer"
    ><img alt='Facebook' src={Facebook} className='w-8 h-8 md:w-8 md:h-8 rounded-full' /></a>
                         <a
      href="https://www.instagram.com/p/C7i-cMJsrsq/?igsh=NTc4MTIwNjQ2YQ=="
      target="_blank"
      rel="noopener noreferrer"
    ><img alt='Facebook' src={Instagram} className='w-8 h-8 md:w-8 md:h-8 rounded-full' /></a>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Footer;