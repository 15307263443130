import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer';
import Nav from '../Components/Nav';

const DonatePaymentOptions = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const navigate = useNavigate();

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleContinue = () => {
    if (selectedOption) {
      navigateToPage(selectedOption);
    } else {
      alert('Please select a payment method.');
    }
  };

  const navigateToPage = (option) => {
    switch (option) {
      case 'paypal':
        navigate('/paypal');
        break;
      case 'bitcoin':
        navigate('/bitcoin');
        break;
      case 'bankTransfer':
        navigate('/bank-transfer');
        break;
      case 'flutterwave':
        navigate('/flutterwave');
        break;
      default:
        break;
    }
  };

  return (
    <div>
      
      <div className="flex flex-col  pt-14">
       <div className="flex items-center justify-center text-center">let's make a <div className="pl-2 bg-[#F5EC16]"> difference</div></div>
        <h1 className=" items-center justify-center text-center text-[44px] font-bold mb-4">How would you love to give</h1>
        <div className="flex flex-col items-start justify-start text-start pl-14 mb-4 ml-44">
          <label className="flex mb-4 ">
            <input
              type="radio"
              name="payment"
              value="paypal"
              checked={selectedOption === 'paypal'}
              onChange={handleChange}
              className="form-radio text-indigo-600 h-4 w-4"
            />
            <span className="ml-2">Via Paypal</span>
          </label>
          <label className="flex items-center mb-4">
            <input
              type="radio"
              name="payment"
              value="bitcoin"
              checked={selectedOption === 'bitcoin'}
              onChange={handleChange}
              className="form-radio text-indigo-600 h-4 w-4"
            />
            <span className="ml-2">Bitcoin Wallet Address</span>
          </label>
          <label className="flex items-center mb-4">
            <input
              type="radio"
              name="payment"
              value="bankTransfer"
              checked={selectedOption === 'bankTransfer'}
              onChange={handleChange}
              className="form-radio text-indigo-600 h-4 w-4"
            />
            <span className="ml-2">Bank Transfer</span>
          </label>
          <label className="flex items-center mb-4">
            <input
              type="radio"
              name="payment"
              value="flutterwave"
              checked={selectedOption === 'flutterwave'}
              onChange={handleChange}
              className="form-radio text-indigo-600 h-4 w-4"
            />
            <span className="ml-2">Via Flutterwave</span>
          </label>
        </div>

        <button
          onClick={handleContinue}
          className=" px-6 py-5  justify-center bg-[#73AFC7] text-white items-center text-center text-[20px] font-[500] rounded-md shadow-md cursor-pointer hover:bg-blue-600 h-[69px] w-[269px]  mt-9 mb-60 ml-96"
        >
          Continue
        </button>
      </div>
      
    </div>
  );
};

export default DonatePaymentOptions;