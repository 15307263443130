import SixCards from "../Components/Cards";
import ConnectWithUs from "../Components/Connect_with_us";
import Donate from "../Components/Donate";
import Footer from "../Components/Footer";
import Nav from "../Components/Nav";
import NoBArrierTech from "../Components/NoBarrierTech";
import ImageSlider from "../Components/Slider";
import TechSkill from "../Components/TechSkill";
import TechSkillSecond from "../Components/TechSkillSecond";
import TechStack from "../Components/TechStack";
import WaitingForYou from "../Components/WaitingForYou";
import Introduction from "../New_components.jsx/Introduction";
import NoBarrierTechSecond from "../New_components.jsx/NoBarrierTechSecond";

const Home = () => {
    const cards = [
        {
          id: 1,
          frontContent: <h2 className="text-black">Front Content 1</h2>,
          backContent: <button className="bg-blue-500 text-black py-2 px-4 rounded">Click Me</button>,
        },
    
        {
          id: 2,
          frontContent: <h2 className="text-black">Front Content 1</h2>,
          backContent: <button className="bg-blue-500 text-black py-2 px-4 rounded">Click Me</button>,
        },
    
        {
          id: 3,
          frontContent: <h2 className="text-black">Front Content 1</h2>,
          backContent: <button className="bg-blue-500 text-black py-2 px-4 rounded">Click Me</button>,
        },
    
        {
          id: 4,
          frontContent: <h2 className="text-black">Front Content 1</h2>,
          backContent: <button className="bg-blue-500 text-black py-2 px-4 rounded">Click Me</button>,
        },
    
        {
          id: 5,
          frontContent: <h2 className="text-black">Front Content 1</h2>,
          backContent: <button className="bg-blue-500 text-black py-2 px-4 rounded">Click Me</button>,
        },
    
        {
          id: 6,
          frontContent: <h2 className="text-black">Front Content 1</h2>,
          backContent: <button className="bg-blue-500 text-black py-2 px-4 rounded">Click Me</button>,
        },
        // Add more card objects as needed
      ];
    return (
        <div className="bg-slate-900">
             
      {/* <TechSkill/> */}
      {/* <TechSkillSecond /> */}
      <Introduction />
      <WaitingForYou />
      <NoBarrierTechSecond />
      {/* <NoBArrierTech /> */}
      {/* <ImageSlider /> */}
       {/* <div className="flex flex-wrap h-[500px] bg-white">
      {cards.map((card) => (
        <Card key={card.id} frontContent={card.frontContent} backContent={card.backContent} />
      ))}
    </div> */}
      {/* <SixCards /> */}
      <TechStack />
      <Donate />
      <ConnectWithUs />
      
        </div>
    )
}

export default Home;