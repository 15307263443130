import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const QuestionAnswer = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-4 border-b pb-2">
      <div
        onClick={toggleOpen}
        className="flex justify-between items-center cursor-pointer"
      >
        <h3 className="text-xl font-semibold">{question}</h3>
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      {isOpen && <p className="mt-2">{answer}</p>}
    </div>
  );
};

export default QuestionAnswer;